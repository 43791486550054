import React, {FunctionComponent} from 'react';

import Page from '../../../domain/Page/Page';

import {useAppDispatch} from '../../../store/hook';
import {updateNextPageId} from '../../../store/wizzard/wizzardStep';
import storage from '../../util/storage';

type Props = {
  page: Page,
  currentPageId: string,
  number: string,
  progress: number,
};

function handleClickStep(dispatch, id) {
  dispatch(updateNextPageId({'id': id}))
  window.location.href = `/form/${id}`
}

const WizardStepComponent: FunctionComponent<Props> = ({page, currentPageId, number, progress}) => {
  const dispatch = useAppDispatch()

  const dossierState = storage.getDossierState()
  const role = localStorage.getItem('role')


  const isComplete = page.id !== currentPageId && progress === 100
  const wizzardActive = page.id === currentPageId ? 'wizzard__step--active' : ''
  
  const handleWizzardCompleteClassName = (isComplete) ?
      ('signed' !== dossierState && 'signature' !== dossierState) || ('back_office_user' === role) ?
          'wizzard__step--complete' :
          'wizzard__step--complete-signed' : ''
  const wizzardBackOffice = 'back_office_user' === role ? 'wizzard__step--back-office' : ''

    // Click on wizard activated if folder not in 'signature' or 'signed' or if specific role
    const wizardElementWithClickEvent =
        ((isComplete && 'signed' !== dossierState && 'signature' !== dossierState) || 'back_office_user' === role)
         &&  (
          <div onClick={() => handleClickStep(dispatch, page.id)}>
            <>
              <div className="step__number">{number}</div>
              <div className="step__title">{page.title}</div>
            </>
          </div>
         ) || (
          <>
            <div className="step__number">{number}</div>
            <div className="step__title">{page.title}</div>
          </>
         )

    return (
      <div className={`wizzard__step ${wizzardActive} ${wizzardBackOffice} ${handleWizzardCompleteClassName}`}>
        <div className="step__progression">
          <div className="progress" style={{width: `${progress}%`}}></div>
        </div>
         {wizardElementWithClickEvent}
      </div>

  );
}

export default WizardStepComponent;
