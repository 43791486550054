import FolderGatewayInterface from "../../domain/Folder/FolderGatewayInterface";
import {Folder} from '../../domain/Folder/Folder';
import FolderAndPageId from '../../domain/Folder/FolderAndPageId'
import StoreGatewayInterface from '../../domain/Store/StoreGatewayInterface';
import Receipt from "../../domain/Receipt/Receipt";

export default class GetDetailAndStoreItUseCase
{
  public fileGatewayInterface;
  public storeGateway;

  constructor(FileGatewayInterface: FolderGatewayInterface, StoreGateway: StoreGatewayInterface) {
    this.fileGatewayInterface = FileGatewayInterface;
    this.storeGateway = StoreGateway;
  }

  execute(folderId: string): Promise<FolderAndPageId|null> {
    return this.fileGatewayInterface.getFolder(folderId)
      .then((response) => {
        const folder = new Folder(
          response.dossier.id,
          response.dossier.form_id,
          response.dossier.client_id,
          response.dossier.advistor_id,
          response.dossier.last_submitted_block_id,
          response.dossier.values,
          response.dossier.direct,
          response.dossier.receipts,
          response.dossier.authorized_clients
        )

        const folderAndPageId = new FolderAndPageId(folder, response.page_id, this.getUrl(folder, response.page_id))

        this.storeGateway.save(folder.values)
        this.storeGateway.saveDirect(folder.direct ? folder.direct : false)
        this.storeGateway.saveClientId(folder.client_id)
        this.storeGateway.saveReceipts(folder.receipts)
        this.storeGateway.saveAuthorizedClients(folder.authorizedClients)

        return folderAndPageId
      });
  }

  getUrl(folder: Folder, pageId: string): string {
    let url = '/form'

    if (null !== pageId) {
      url+= `/${pageId}`

      if (null !== folder && null !== folder.last_submitted_block_id) {
        url+= `/${folder.last_submitted_block_id}`
      }
    }

    return url
  }

  downloadSignedDocument(folderId: string): Promise<{ contentBase64: string, filename: string } | null> {
    return this.fileGatewayInterface.downloadSignedFolderDocument(folderId)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return Promise.reject(error)
      });
  }

  getFolderDocuSignHistory(folderId: string): Promise<{ contentBase64: string, filename: string } | null> {
    return this.fileGatewayInterface.folderDocuSignHistory(folderId)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return Promise.reject(error)
      });
  }
}
